import styled from "styled-components";

const StyledDropDown = styled.select`
    width: 100%;
    color: #aaa;
    font-weight: bold;
    background: #0003;
    cursor: pointer;
    border: none;
    border-radius: 20px;
    padding: 19px 28px;
    outline: none;
    font-size: 20px;
    box-shadow: 
        1px 1.5px 4px 0px #0000001A inset,
        1px 1.5px 4px 0px #00000014 inset,
        0px -0.5px 1px 0px #FFFFFF40 inset,
        0px -0.5px 1px 0px #FFFFFF4D inset;
`;

export default StyledDropDown;