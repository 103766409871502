import { useEffect, useRef } from "react";

import Hls from 'hls.js';

const getFileExtension = (fileUrl: string): string => {
    if (!fileUrl)
        return '';

    const parts = fileUrl.split('.');

    if (parts.length > 1) {
        const lastPart = parts[parts.length - 1];
        const cleanExtension = lastPart.split(/#|\?/)[0];

        if (cleanExtension) {
            return cleanExtension.toLowerCase();
        }
    }

    return '';
};

interface DisplayMediaProps {
    shouldPlay: boolean;
    mediaUrl: string;
    onAfterOneSecond?: () => void;
    onMediaEnded?: () => void;
    onMediaError?: () => void;
}

const DisplayMedia: React.FC<DisplayMediaProps> = (props) => {

    const fileExtension = getFileExtension(props.mediaUrl);

    const imageExtensions = ['png', 'jpeg', 'jpg'];
    const videoExtensions = ['m3u8'];

    const mediaIsImage = imageExtensions.includes(fileExtension);
    const mediaIsVideo = videoExtensions.includes(fileExtension);

    const imageRef = useRef<HTMLImageElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const mediaLoadedRef = useRef<boolean>(false);

    const playMedia = () => {
        if (mediaIsVideo && videoRef.current) {
            videoRef.current.play().catch((err) => {
                console.error("Error attempting to play:", err);
                if (videoRef.current)
                    videoRef.current.controls = true;
            });

            setTimeout(() => {
                if (props.onAfterOneSecond)
                    props.onAfterOneSecond();
            }, 1000);
        } else if (mediaIsImage && imageRef.current) {
            if(props.onMediaEnded)
                props.onMediaEnded();
        }
    };

    const handleMediaEnded = () => {
        if (mediaIsVideo && props.onMediaEnded)
            props.onMediaEnded();
    };

    const handleVideoTimeUpdate = () => {
        if(videoRef.current) {
            if(videoRef.current.currentTime >= videoRef.current.duration) {
                handleMediaEnded();
            }
        }
    };

    const recoveredOnceRef = useRef<boolean>(false);

    const mediaCanNotPlayRef = useRef<boolean>(false);

    useEffect(() => {
        mediaLoadedRef.current = false;
        
        if (props.mediaUrl && mediaIsVideo && videoRef.current) {

            videoRef.current.addEventListener('timeupdate', handleVideoTimeUpdate);

            if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(props.mediaUrl);
                hls.attachMedia(videoRef.current);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    mediaWasLoaded();
                });

                hls.on(Hls.Events.ERROR, (event, data) => {
                    if (data.fatal) {
                        if(data.type === Hls.ErrorTypes.NETWORK_ERROR) {
                            console.error("Fatal network error encountered, trying to recover");
                            hls.startLoad();
                        } else if(data.type === Hls.ErrorTypes.MEDIA_ERROR && !recoveredOnceRef.current) {
                            console.error("Fatal media error encountered, trying to recover");
                            recoveredOnceRef.current = true;
                            hls.recoverMediaError();
                        } else {
                            hls.destroy();
                            mediaCanNotPlayRef.current = true;
                        }
                    }
                });
            } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl') === 'probably') {
                if (videoRef.current) {
                    videoRef.current.src = props.mediaUrl;

                    videoRef.current.addEventListener('loadedmetadata', () => {
                        mediaWasLoaded();
                    });

                    videoRef.current.addEventListener('error', () => {
                        mediaCanNotPlayRef.current = true;
                    });
                }
            } else {
                mediaCanNotPlayRef.current = true;
            }
        } else if (props.mediaUrl && mediaIsImage && imageRef.current) {
            if (imageRef.current.complete) {
                mediaWasLoaded();
            } else {
                imageRef.current.onload = () => {
                    mediaWasLoaded();
                };
            }
        }

        return () => {
            if (mediaIsVideo && videoRef.current)
                videoRef.current.removeEventListener('timeupdate', handleVideoTimeUpdate);
        };

    }, [props.mediaUrl]);

    useEffect(() => {
        if(props.shouldPlay && mediaCanNotPlayRef.current) {
            if(props.onMediaEnded)
                props.onMediaEnded();
        } else if (props.shouldPlay && mediaLoadedRef.current) {
            playMedia();
        }
    }, [props.shouldPlay]);

    const mediaWasLoaded = () => {
        mediaLoadedRef.current = true;

        if (props.shouldPlay) {
            playMedia();
        }
    };

    if (mediaIsImage)
        return (
            <img ref={imageRef} src={props.mediaUrl} style={{
                display: 'block',
                width: '100%',
                //borderRadius: '20px'
            }} />
        );

    if (mediaIsVideo)
        return (
            <video ref={videoRef} 
                style={{
                    display: 'block',
                    width: '100%',
                    borderRadius: '20px'
                    
                }}
                playsInline
                muted
            />
        );

    return null;
};

export default DisplayMedia;